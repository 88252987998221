<template>
  <v-dialog
    v-model="verifyDialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between;"
      >
        Verify phone number
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        Check your SMS messages. We've sent you the PIN at
        {{ formatPhoneNumber(profile.phone) }}
      </v-card-text>
      <v-card-text class="d-flex justify-center">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center mt-5">
            <CodeInput
              loading="false"
              class="input"
              v-model="code"
              v-on:change="onChange"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-text class="d-flex flex-row align-center">
        Didn't receive SMS?
        <v-btn
          text
          class="text-transform-none ml-3"
          @click="onSendCode"
          :disabled="disableResend"
          :loading="sendingCode"
        >
          {{
            resendCount
              ? "Wait " + resendCount + "s to resend Code"
              : "Resend code"
          }}
        </v-btn>
      </v-card-text>
      <v-card-text>
        Need voice code?
        <v-btn
          text
          class="text-transform-none ml-3"
          @click="onSendVoice"
          :disabled="disableResend"
          :loading="sendingCode"
          >{{
            resendCount
              ? "Wait " + resendCount + "s to resend Code"
              : "Send voice"
          }}</v-btn
        >
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="verify"
          class="white--text"
          width="150"
          :loading="loading"
          >Verify</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CodeInput from "vue-pincode-input";

export default {
  components: { CodeInput },
  props: {
    verifyDialog: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Are you sure do you want to delete?",
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      code: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
    };
  },
  methods: {
    ...mapActions("auth", {
      verifyPhone: "verifyPhone",
      sendVerificationCode: "sendVerificationCode",
      sendVoiceCode: "sendVoiceCode",
    }),
    onChange() {
      const inputElement = this.$refs.codeField.$el.querySelector("input");
      inputElement.min = 0;
      inputElement.maxLength = 4;
      if (this.code.match(/^[0-9]+$/) != null) {
        this.message = null;
      } else {
        this.message = "Invalid code";
      }
    },
    verify() {
      if (this.code.length != 4) {
        return;
      }
      this.message = null;
      this.loading = true;
      this.verifyPhone({ code: this.code })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    onSendCode() {
      this.sendingCode = true;
      this.sendVerificationCode()
        .then(() => {
          this.sendingCode = false;
          this.disableResend = true;
          this.resendCount = 60;
          this.countDown();
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    onSendVoice() {
      this.sendingCode = true;
      this.sendVoiceCode()
        .then(() => {
          this.sendingCode = false;
          this.disableResend = true;
          this.resendCount = 60;
          this.countDown();
        })
        .catch((error) => {
          this.sendingCode = false;
          this.disableResend = true;
          this.resendCount = 60;
          this.countDown();
          console.log(error.response.data.message);
        });
    },
    countDown() {
      if (this.resendCount > 0) {
        setTimeout(() => {
          this.resendCount -= 1;
          this.countDown();
        }, 1000);
      } else {
        this.disableResend = false;
      }
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    width: 70%;
    max-width: 900px;
    max-height: 80%;
  }
}
.v-dialog__content {
    z-index: 999999999 !important;
}

</style>
