<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <volunteer-left-section class="hidden-sm-and-down" />
        <feed-section />
        <volunteer-right-section class="hidden-md-and-down" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
      <phone-verification-dialog
        :verifyDialog="verifyPhoneDialog"
        :onClose="onCloseVerify"
        v-if="
          verifyPhoneDialog &&
            !completeProfileDialog &&
            !causeDialog &&
            !interestsDialog &&
            !availabilitiesDialog &&
            !congratsDialog
        "
      />
      <complete-profile-dialog
        :dialog="completeProfileDialog"
        :onClose="onCloseCompleteProfileDialog"
        v-if="
          !verifyPhoneDialog &&
            completeProfileDialog &&
            !causeDialog &&
            !interestsDialog &&
            !availabilitiesDialog &&
            !congratsDialog
        "
      />
      <volunteer-causes-dialog
        :dialog="causeDialog"
        :onClose="onCloseCausesDialog"
        v-if="
          !verifyPhoneDialog &&
            !completeProfileDialog &&
            causeDialog &&
            !interestsDialog &&
            !availabilitiesDialog &&
            !congratsDialog
        "
      />
      <volunteer-interests-dialog
        :dialog="interestsDialog"
        :onClose="onCloseInterestsDialog"
        v-if="
          !verifyPhoneDialog &&
            !completeProfileDialog &&
            !causeDialog &&
            interestsDialog &&
            !availabilitiesDialog &&
            !congratsDialog
        "
      />
      <volunteer-availabilities-dialog
        :dialog="availabilitiesDialog"
        :onClose="onCloseAvailabilities"
        v-if="
          !verifyPhoneDialog &&
            !completeProfileDialog &&
            !causeDialog &&
            !interestsDialog &&
            availabilitiesDialog &&
            !congratsDialog
        "
      />
      <volunteer-congrats-dialog
        :dialog="congratsDialog"
        :onClose="onCloseCongratsDialog"
        :is-congrats="lastStep"
        v-if="
          !verifyPhoneDialog &&
            !completeProfileDialog &&
            !causeDialog &&
            !interestsDialog &&
            !availabilitiesDialog &&
            congratsDialog
        "
      />
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PhoneVerificationDialog from "@/components/common/PhoneVerificationDialog.vue";
import FeedSection from "../event_feeds/FeedSection.vue";
import CompleteProfileDialog from "./complete_profile_dialog/CompleteProfileDialog.vue";
import VolunteerCausesDialog from "./complete_profile_dialog/VolunteerCausesDialog.vue";
import VolunteerLeftSection from "./VolunteerLeftSection.vue";
import VolunteerRightSection from "./VolunteerRightSection.vue";
import VolunteerInterestsDialog from "./complete_profile_dialog/VolunteerInterestsDialog.vue";
import VolunteerAvailabilitiesDialog from "./complete_profile_dialog/VolunteerAvailabilitiesDialog.vue";
import VolunteerCongratsDialog from "./complete_profile_dialog/VolunteerCongratsDialog.vue";
export default {
  components: {
    FeedSection,
    VolunteerLeftSection,
    VolunteerRightSection,
    PhoneVerificationDialog,
    CompleteProfileDialog,
    VolunteerCausesDialog,
    VolunteerInterestsDialog,
    VolunteerAvailabilitiesDialog,
    VolunteerCongratsDialog,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      verifyPhoneDialog: false,
      completeProfileDialog: false,
      causeDialog: false,
      interestsDialog: false,
      availabilitiesDialog: false,
      congratsDialog: false,
      notifications: [],
      errorNotifications: [],
      passedVerification: false,
      lastStep: true,
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    checkPhoneVerified() {
      if (!this.profile._id || this.passedVerification) return;
      if (!this.profile.phone_verified) {
        if (!this.profile.phone_verification_code) {
          //implement send verification code
          console.log("implement send verification code");
        }
        setTimeout(() => {
          this.verifyPhoneDialog = true;
        }, 500);
        return;
      }
      this.checkCompletedProfile();
    },
    checkCompletedProfile() {
      if (this.profile && this.profile._id && !this.profile.about_me) {
        setTimeout(() => {
          this.completeProfileDialog = true;
        }, 500);
        return;
      }
      this.checkCauses();
    },
    onCloseAllDialog() {
      this.verifyPhoneDialog = false;
      this.completeProfileDialog = false;
      this.causeDialog = false;
      this.interestsDialog = false;
      this.availabilitiesDialog = false;
      this.congratsDialog = false;
    },
    onCloseVerify() {
      this.passedVerification = true;
      this.onCloseAllDialog();
      this.checkCompletedProfile();
    },
    onCloseCompleteProfileDialog() {
      this.onCloseAllDialog();
      this.checkCauses();
    },
    checkCauses() {
      if (!this.profile.causes || this.profile.causes.length == 0) {
        setTimeout(() => {
          this.causeDialog = true;
        }, 500);
      } else {
        this.checkInterests();
      }
    },
    onCloseCausesDialog() {
      this.onCloseAllDialog();
      this.checkInterests();
    },
    checkInterests() {
      if (!this.profile.interests || this.profile.interests.length == 0) {
        setTimeout(() => {
          this.interestsDialog = true;
        }, 500);
      } else {
        this.checkAvailabilities();
      }
    },
    onCloseInterestsDialog() {
      this.onCloseAllDialog();
      this.checkAvailabilities();
    },
    checkAvailabilities() {
      if (
        !this.profile.availabilities ||
        this.profile.availabilities.length == 0
      ) {
        setTimeout(() => {
          this.availabilitiesDialog = true;
        }, 500);
      } else {
        this.showCongratsDialog();
      }
    },
    onCloseAvailabilities() {
      this.onCloseAllDialog();
      this.showCongratsDialog();
    },
    showCongratsDialog() {
      console.log("show congrats dialog");
      if (
        this.profile.phone_verified &&
        this.profile.about_me &&
        this.profile.causes.length != 0 &&
        this.profile.interests.length != 0 &&
        this.profile.availabilities.length != 0
      ) {
        if (!this.profile.email_verified) {
          setTimeout(() => {
            this.lastStep = true;
            this.congratsDialog = true;
          }, 500);
        }
      } else if (
        !this.phoneVerificationDialog &&
        !this.completeProfileDialog &&
        !this.causeDialog &&
        !this.interestsDialog &&
        !this.availabilitiesDialog &&
        !this.profile.email_verified
      ) {
        setTimeout(() => {
          this.lastStep = false;
          this.congratsDialog = true;
        }, 500);
      } else {
        this.onCloseAllDialog();
      }
    },
    onCloseCongratsDialog() {
      this.onCloseAllDialog();
      this.$router.push({ name: "volunteer-profile" });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    profile: function(newValue) {
      if (this.$route.query.welcome && newValue) {
        this.checkPhoneVerified();
      }
    },
  },
  mounted() {
    if (this.$route.query.welcome && this.profile) {
      this.checkPhoneVerified();
    }
    this.notifications = this.$route.params.notifications;
    this.errorNotifications = this.$route.params.error;
  },
};
</script>
<style scoped></style>
